@font-face {
  font-family: 'm';
  src: url('/assets/fonts/GothamPro.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'm';
  src: url('/assets/fonts/GothamPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
.mob-menu {
  padding:10px;
  height:45px;
  overflow:auto;
  display: grid;
  grid-template-columns:repeat(5, 1fr);
  grid-column-gap:25px;
  grid-gap:25px;
}

.logos {
  height: 100%;
}
.fooot_r {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
}
.cvbxasdeq-f a:before {
  content: 'Играть';
  position: absolute;
  opacity: 0;
  z-index: 333;
  width: 176px;
  height: 62px;
  background-color: #f6f5f5;
  font-weight: bold;
  color: #2675ce;
  display: flex;
  align-items: center;
  bottom: -31px;
  justify-content: center;
}
.teruyZZsad img {
  width: 1300px;
  border-radius: 15px;
  margin-top: 15px !important;
}
.cvbxasdeq-f a:hover:before {
  opacity: 1;
}
.nbasdhg {
  display: flex;
  width: 100%;
  justify-content: safe;
  flex-direction: column;
}
.fot_l {
  width: 100%;
  display: flex;
  align-items: center;
  align-items: center;
  justify-content: center;
}

.fbasdfaer-footer,
#wpadminbar {
  display: none;
}
h2,
h3,
h4,
h5,
h6 {
  padding-left: 0px;
}
p,
li {
  line-height: 1.6;
  padding-left: 0px;
  position: relative;
}
ul {
  position: relative;
}
wp:after,
uwxl:after {
  position: absolute;
  left: 0px;
  top: 30px;
  content: ' ';
  width: 20px;
  height: 5px;
  background-color: #d62c57;
}
h1 {
  /*
          color: #fff;
     font-size:30px;
     padding-left:50px;
     letter-spacing: 1px;
      */
  font-size: 30px;
  color: #000 !important;
  display: block;
  margin-top: 30px;
  letter-spacing: 1px;
  margin-bottom: 0px;
  position: relative;
  /*padding-left:50px*/
}
/*  h2,h3,h4,h5,h6,p strong{ */
h2,
h3,
h4,
h5,
h6 {
  font-size: 24px;
  display: block;
  /*margin-top:40px;*/
  letter-spacing: 1px;
  margin-bottom: 0px;
  position: relative;
  /*padding-left:50px */
}
/* h1:before, h2:before, h3:before, h4:before, h5:before, h6:before,p strong:before { */
/*h1:before, h2:before, h3:before, h4:before, h5:before, h6:before {
    width: 35px;
    height: 5px;
    content: '  ';
    background: #2675CE;
    left: 0px!important;
    top: 11px;
    position: absolute;
}
*/

h2 {
  color: #000 !important;
  margin-top: 0px;
}
body {
  margin: 0px;
  font-family: 'm';
  /*letter-spacing: 1px;*/
  position: relative;
  background: #fff;
  overflow-x: hidden;
}
.vbbvcxcvxsda_m {
  width: 100%;
  height: 2px;
  background: #826f54;
  margin-bottom: 40px;
}
.vbhjtaweb_end {
  width: 213px;
  height: 56px;
  border-radius: 5px;
  border: 2px solid #2675ce;
  color: #fff;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: Pointer;
  margin: 50px auto;
  margin-top: 20px;
}
.vbhjtaweb_end:hover {
  background: #2675ce;
  color: #fff;
}
.dsdsc {
  height: 2px;
  background: rgba(255, 255, 255, 0.144);
  width: 100%;
  margin-bottom: 40px;
}
.vbhjtaweb_end img {
  width: 20px !important;
  margin-right: 10px;
  transition: 0.3s;
}
.vbhjtaweb_end:hover img {
  transform: rotateX(180deg);
}
.amokv {
  width: 100%;
  height: 76px;
  text-align: center;
  color: #4175ff;
  display: flex;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  text-transform: uppercase;
  z-index: 100;
}
.vbcasdasdq_qq {
  width: auto !important;
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}
.cvbxasdeq-f a:hover .amokv {
  background: #4175ff;
  color: #fff;
}
.cvbxasdeq-f a:hover .cvbxasdeq-f-0 {
  width: 100%;
  height: 60px;
  bottom: -60px;
  position: absolute;
  z-index: 3;
  -webkit-box-shadow: 0px -34px 39px -7px rgba(244, 28, 42, 1);
  -moz-box-shadow: 0px -34px 39px -7px rgba(244, 28, 42, 1);
  box-shadow: 0px -34px 39px -7px rgba(244, 28, 42, 1);
}
.cvbxasdeq-f1,
.cvbxasdeq-f2 {
  height: 100%;
  background-size: 100% 100%;
  cursor: pointer;
  width: 100%;
}
.casino4 span {
  display: flex;
  align-items: center;
}
.amo-right {
  padding-left: 20px;
  width: 25%;
  box-sizing: border-box;
}
.cvbxasdeq-f a {
  width: 32%;
  font-size: 18px;
  color: #9b9084;
  margin-bottom: 3%;
  height: 250px;
  background-size: 100% 100%;
  background: transparent;
  cursor: pointer;
  position: relative;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 5px solid #2675ce;

  justify-content: center;
}
header .ghadsbvn div:hover:after {
  content: ' ';
  position: absolute;
  height: 3px;
  border-radius: 0px;
  left: 0px;
  width: 100%;
  display: block;
  top: 47px;
  background-color: #2675ce;
}
header .ghadsbvn div:hover {
  color: #2675ce !important;
  z-index: 2;
}
.strange {
  top: 10px;
  z-index: 0 !important;
  left: 5px;
  position: absolute;
  width: 220% !important;
  max-width: 220%;
}
.igra {
  width: 148px;
  height: 50px;
  border-radius: 25px;
  border: 1px solid #f09235;
  z-index: 2;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #f09235;
  justify-content: center;
  box-sizing: border-box;
  margin: auto;
  margin-top: 20px;
}
.cvbxasdeq-f a:hover .igra {
  color: #fff;
  background: #f09235;
}
.cvbxasdeq-f a:hover {
  background-color: #13222b;
}
.f4 {
  width: 160px;
  height: 45px;
  border-radius: 22px;
  border: 1px solid #007fde;
  text-align: center;
  color: #fff;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}
.f4:hover {
  color: #4175ff;
  background: #fff;
}
.cvbxasdeq-f1 {
  /*background:url("o1.png.html") no-repeat;*/
  background-position: center top;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% auto;
  margin-bottom: 0px;
  height: 100%;
}
.cvbxasdeq-f2 {
  background: #2c1051;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% auto;
  margin-bottom: 0px;
  color: #fff;
  height: 80px;
}
.cvbxasdeq-f a:hover .cvbxasdeq-f2 {
  background: #2c1051;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: 100% auto;
  margin-bottom: 0px;
  color: #fff;
  height: 80px;
  transform: translateY(-190px);
  background: transparent;
  font-size: 20px;
  z-index: 333;
  font-weight: 800;
  /* box-shadow: 0px 8px 7px rgba(255,165,0,0.15);
     */
  color: #f0ad00;
}
.cvbxasdeq-f {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cvbxasdeq-f-end {
  width: 141px;
  height: 39px;
  text-align: center;
  color: #fafbe5;
  display: flex;
  align-items: center;
  transition: 0.3s;
  justify-content: center;
  margin-right: 0px;
  cursor: pointer;
  margin-bottom: 20px;
  margin-left: auto;
  margin-top: 30px;
}
.bcnvbcnv {
  margin-right: 0px !important;
}
.cvbxasdeq-f-end img {
  width: 20px !important;
  margin-right: 10px;
  transition: 0.3s;
}
.cvbxasdeq-f-end a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.cvbxasdeq-f-end:hover img {
  transform: rotate(360deg);
}
img {
  max-width: 100%;
}
.casino1 {
  font-size: 24px;
  color: #4175ff;
  display: flex;
  align-items: center;
}
.casino3 {
  font-size: 20px;
  color: #4175ff;
}
.vxcbcvxasde1 {
  font-size: 30px;
  margin-top: 30px;
  color: #0c314e;
  font-weight: bold;
  position: relative;
}
a {
  color: inherit;
  text-decoration: none;
}
.vxcbcvxasde2 {
  margin-top: 20px;
  font-size: 18px;
  padding-right: 40px;
  line-height: 1.5;
  color: #8d8a9c;
}
.vxcbcvxasde3 {
  font-size: 20px;
  color: #0c314e;
  margin-top: 30px;
  position: relative;
  font-weight: 700;
}
header {
  height: 80px;
  background-color: #fff;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.04), 0 0 2px rgba(0, 0, 0, 0.06),
    0 4px 8px rgba(0, 0, 0, 0.04);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 4;
}
.teruyZZsad {
  /* margin-bottom:30px; */
}
.nmasdfr_z img {
  width: 100%;
  z-index: 3;
}
.nmasdfr_z {
  margin-left: 0px !important;
}
.left-footer {
  margin-right: 40px;
}
.left-footer img {
  margin-bottom: 103px;
  margin-right: 30px;
  margin-top: 5px;
}
footer {
  margin-top: 40px;
  background: #080101d3;
  position: relative;
  font-size: 14px;
  display: -webkit-flex;
  display: flex;
  /*line-height: 24px;*/
  box-sizing: border-box;
  border-top: 1px SOLID #383838;
  flex-direction: row-reverse;
  color: #8d8e94;
}
.f1 img {
  max-width: 80%;
}
.f3 img {
  margin-top: 20px;
  margin-right: 30px;
}
.f2 {
  display: flex;
  align-items: center;
  color: #fafbe5;
  font-size: 14px;
}
.footer-top {
  flex-direction: row;
  flex-wrap: nowrap;
  display: flex;
  height: 85px;
  border-radius: 42px;
  background-image: linear-gradient(0deg, #eac186 0%, #dfdad5 100%);
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
.nbasdhg a {
  padding: 0px 10px;
  color: #fff;
}
.ghadsbvn a {
  margin: 0px 10px;
}
.footer-top div {
  margin-right: 25px;
}
footer a:hover {
  color: #2675ce !important;
  text-decoration: underline;
}
footer .hujnsacbdfv_s {
  display: -webkit-flex;
  display: flex;
  flex-direction: row !important;
  justify-content: left;
  color: #fff;
  align-items: center;
  font-size: 16px;
  flex-wrap: wrap;
}
.hujnsacbdfv_s {
  width: 1300px;

  margin: 0 auto;
}
header .hujnsacbdfv_s {
  display: -webkit-flex;
  align-items: baseline;
  display: flex;
  flex-direction: row;
  align-items: normal;
  height: 100%;
  justify-content: flex-end;
}
header .hujnsacbdfv_s div {
  display: -webkit-flex;
  align-items: center;
  display: flex;
  position: relative;
  margin-right: auto;
}

.amol2 {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
}
.amol2 div {
  padding: 0px 12px;
  color: #fff;
  position: relative;
  z-index: 22;
}
.bcnvbcnv input {
  border: 0px;
  width: 330px;
  margin-bottom: 20px;
  font-size: 16px;
  padding-left: 20px;
  height: 59px;
  border-radius: 30px;
  background-color: #444249;
}
.vxcbcvxasde_left {
  width: 100%;
  text-align: left;
  box-sizing: border-box;
}
footer .vbbvcxcvxsda_m {
  width: 100%;
  height: 2px;
  background: #826f54;
  margin-bottom: 0px;
}
.jn_bsadflk3,
.fgbwexz4 {
  border-radius: 200px;
  width: 151px;
  height: 56px;
  transition: 0.3s;
  color: #000;
  justify-content: center;
  border:2px solid;
}

.jn_bsadflk3 {
  width:120px !important; 
  background-color: #cd3334;
  /*border:1px solid #2675CE;*/
  color:#fff;
}

.jn_bsadflk3 {
  margin-right: 15px !important;
  width: 110%;
}
.jn_bsadflk3:hover {
  transition:0,2;
  background-color: #e95e5e;
}
.fgbwexz4:hover {
  /*background:#fff;*/
  color:#fff;
  background-color: #cd3334;
}
.amol2 {
  margin-right: 15px !important;
  margin-left: -50px !important;
}
.ghadsbvn div {
  color: #000;
  font-size: 18px;
  padding: 0px 5px;
}
.vxcbcvxasde .hujnsacbdfv_s {
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}
.vxcbcvxasde-right img {
  position: sticky;
  position: -webkit-sticky;
  top: 10px;
}
.amo-right img {
  width: 100%;
}
.amo-right .right2 {
  border: 1px solid #e8e8e8;
  padding: 10px;
  position: relative;
  background: #fff;
  border-radius: 10px;
}
// ul {
//     padding: 0px;
//     display: flex;
//     flex-direction: column;
//     margin-bottom: 0px;
//}
// li{
//     font-size: 16px;
//     ALIGN-ITEMs: center;
//     display: -webkit-flex;
//     display: flex;
//     padding:5px 0px;
//     position:relative;
//     list-style: none;
//}
li img {
  margin: 0px 20px;
  width: 20px;
}
.righter3 {
  text-align: center;
  height: 113px;
  border-radius: 10px;
  background-color: #fda700;
  font-size: 24px;
  font-style: italic;
  font-weight: bold;
  margin-top: 20px;
  box-sizing: border-box;
  color: #fff;
  background: url('') no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 25px;
}
.righter31 {
  height: 64px;
  background-color: #092134;
  width: 90%;
  margin: 0 auto;
  margin-top: 0px;
  display: -webkit-flex;
  display: flex;
  font-size: 18px;
  flex-direction: column;
  line-height: 64px;
  align-items: center;
  padding: 0px 25px;
  border-radius: 10px;
  margin-left: 15px;
}
.right2 input {
  height: 59px;
  border: 0px;
  width: 100%;
  background-color: #e8e8e8;
  font-size: 16px;
  padding-left: 30px;
  box-sizing: border-box;
  margin-bottom: 20px;
  position: relative;
}
.vdfwqzm_ert img {
  /* width: 100%; */
  /* height: 100%; */
}
.vxcbcvxasde-img {
  text-align: center;
  font-size: 16px;
  color: #8d8a9c;
  font-style: italic;
}
.promo {
  height: 102px;
  background-color: #f2f2f4;
  margin-top: 20px;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: center;
  align-items: center;
  width: 100%;
}
.promo1 {
  font-size: 22px;
  font-weight: bold;
  color: #0c314e;
  padding-left: 30px;
}
.promo1 span {
  color: #fda700;
}
.vdfwqzm_ert {
  width: 100%;
  color: #000;
  text-align: left;
  line-height: 1.4;
  display: flex;
  flex-direction: row;
}
.promo2 {
  width: 253px;
  height: 57px;
  margin-right: 20px;
  cursor: pointer;
  color: #fff;
  text-align: center;
  line-height: 57px;
  border-radius: 5px;
  background-color: #313036;
}
.vxcbcvxasde21 {
  width: 253px;
  height: 63px;
  line-height: 65px;
  font-size: 18px;
  margin-top: 5px;
  background-color: #fda700;
  border-radius: 10px;
  text-align: center;
  color: #fff;
}
.vxcbcvxasde2.flex {
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  padding-right: 0px;
}
.flex .vxcbcvxasde21 {
}
.vxcbcvxasde20 {
  font-size: 18px;
  padding-right: 50px;
  color: #8d8a9c;
  line-height: 1.5;
  margin-top: 20px;
}
.plashka {
  position: absolute;
  height: 67px;
  box-shadow: 0px 11px 38px rgba(49, 48, 54, 0.22);
  background-color: rgba(84, 84, 90, 0.8196078431372549);
  bottom: 277px;
  width: 100%;
  top: auto;
}
.plashka * {
  cursor: pointer;
}
.plashka .vxcbcvxasde21 {
  height: 53px;
  line-height: 55px;
}
.plashka .hujnsacbdfv_s {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.bgnasdfrt_nzcx {
  display: none !important;
}
@media screen and (max-width: 1400px) and (min-width: 1000px) {
  .cvbxasdeq-f a:hover .cvbxasdeq-f2 {
    transform: translateY(-140px);
  }
  .cvbxasdeq-f a {
    font-size: 14px;
  }
  .ghadsbvn div {
    font-size: 18px;
    padding: 0px 5px;
  }
  .f2 img {
    margin-right: 35px;
    width: 50%;
  }
  .footer-top {
    font-size: 12px;
  }
  .cvbxasdeq-f a {
    height: 220px;
  }
  li img {
    margin: 0px 20px;
    width: 16px;
    margin-left: 5px;
  }
  .vdfwqzm_ert {
    width: 100%;
  }
  .amo-right {
    padding-left: 0px;
    width: 30%;
  }
  .amol2 {
    margin-right: 10px !important;
  }
  header .hujnsacbdfv_s div {
    white-space: nowrap;
  }
  .vdfwqzm_ert img {
    width: 97%;
  }
  .promo {
    width: 97%;
  }
  .left-footer img {
    margin-bottom: 110px;
    margin-top: 5px;
  }
  .jn_bsadflk3 {
    padding: 0px 10px;
  }
  .fgbwexz4 {
    padding: 0px 10px;
  }
  .jn_bsadflk3 {
    width: 135px;
  }
  .hujnsacbdfv_s {
    width: 1050px;
    width: 100%;
  }
}
@media screen and (max-width: 1000px) {
  .cvbxasdeq-f a:before {
    width: 80px;
    height: 30px;
    top: auto;
    bottom: -15px;
  }
  .cvbxasdeq-f a:hover .cvbxasdeq-f2 {
    transform: translateY(-50px);
    font-size: 14px;
  }
  .bgnasdfrt_nzcx {
    display: block !important;
  }
  .cvbxasdeq-f {
    width: 90vw;
  }
  .vbcasdasdq_qq {
    width: 90vw !important;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .nmasdfr_z img {
    width: 100%;
    z-index: 3;
    margin-bottom: 0px;
  }
  .nebgnasdfrt_nzcx {
    display: none !important;
  }
  .hujnsacbdfv_s {
    width: 100%;
    padding: 0px 5vw;
  }
  .amo-right,
  .plashka,
  .left-footer span {
    display: none;
  }
  footer .hujnsacbdfv_s {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
  }
  .left-footer img {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .right-footer {
    font-size: 12px;
    margin-top: 10px;
  }
  .vxcbcvxasde .hujnsacbdfv_s {
    padding: 0px 5vw;
    display: -webkit-flex;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .f4 {
    width: 145px;
  }
  .vxcbcvxasde1 {
    font-size: 23px;
    position: relative;
  }
  .vxcbcvxasde3 {
    font-size: 16px;
  }
  .vxcbcvxasde2 {
    margin-top: 20px;
    font-size: 14px;
    padding-right: 0px;
    line-height: 1.5;
    color: #8d8a9c;
  }
  .vxcbcvxasde20 {
    padding-right: 0px;
  }
  img {
    max-width: 90vw;
  }
  .vxcbcvxasde21 {
    width: 100%;
    height: 63px;
    line-height: 63px;
    font-size: 18px;
    margin-top: 5px;
    background-color: #092134;
    text-align: center;
    color: #fff;
    margin-top: 20px;
  }
  header {
    display: none;
  }
  .vxcbcvxasde2.flex {
    display: -webkit-flex;
    display: flex;
    padding-right: 0px;
    flex-direction: column;
  }
  .promo2 {
    width: 154px;
    height: 47px;
    line-height: 47px;
    border: 1px solid #fda700;
  }
  .promo1 span {
    color: #fda700;
    display: block;
  }
  .promo1 {
    font-size: 16px;
    font-weight: bold;
    color: #0c314e;
    padding-left: 10px;
  }
  .teruyZZsad {
    margin-bottom: 0px;
  }
  .right-footer:after {
    content: '© 2019 Все права защищены.';
    display: block;
    margin-top: 20px;
  }
  .bgnasdfrt_nzcx {
    display: block;
  }
  header {
    height: auto;
    z-index: 100000;
    left: 0px;
    right: 0px;
    height: auto;
  }
  .vxcbcvxasde-right {
    display: none;
  }
  .pros {
    display: none;
  }
  header .hujnsacbdfv_s {
    flex-direction: row;
    padding: 0px 5vw;
    flex-wrap: wrap;
    box-sizing: border-box;
    padding-top: 10px;
  }
  .footer-top {
    width: 100vw;
    flex-direction: row;
    flex-wrap: nowrap;
    display: flex;
    font-size: 14px;
    padding-left: 0%;
    box-sizing: border-box;
    margin-right: 0px;
    border-radius: 0px;
  }
  .footer-top div {
    margin-right: 25px;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  header {
    margin: 0px;
  }
  .f1 {
    text-align: center;
  }
  .clss div {
    width: 50%;
  }
  .vbhjtaweb_end {
    margin-top: 20px;
  }
  .teruyZZsad .nebgnasdfrt_nzcx {
    display: none !important;
    width: 160%;
    max-width: 200%;
    margin-left: -30% !important;
  }
  .vbbvcxcvxsda_m {
    display: none;
  }
  footer .vbbvcxcvxsda_m {
    display: block;
    margin-top: 15px;
    margin-bottom: 25px;
  }
  .fooot_r {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: end;
    padding-left: 0px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .igra {
    width: 100px;
    height: 28px;
    border-radius: 19px;
    box-shadow: 0 0 18px rgba(240, 146, 53, 0.38);
    font-size: 10px;
    margin-top: 0px;
  }
  .fot_l {
    width: 100%;
    color: #99a39d;
    font-size: 13px;
    margin-top: 20px;
    justify-content: normal;
  }
  .nbasdhg a {
    padding: 0px 4vw;
    margin-bottom: 10px;
    padding-left: 0px;
  }
  .nbasdhg {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: left;
  }
  .f2 img {
    margin-right: 0px;
    width: 100%;
    margin-left: 0px;
    margin-bottom: 15px;
  }
  .f2 {
    margin-right: 0px;
    font-size: 14px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
  .f3 img {
    margin-top: 20px;
    margin-right: 0px;
  }
  footer {
    height: auto;
    margin-top: 0px;
  }
  footer .hujnsacbdfv_s {
    flex-direction: row-reverse !important;
  }
  .cvbxasdeq-f1,
  .cvbxasdeq-f2 {
    height: 100px;
  }
  footer .hujnsacbdfv_s {
    justify-content: center;
    flex-wrap: wrap;
  }
  img {
    /* max-width: 100vw; */
  }
  .amokv {
    width: 101%;
  }
  .nmasdfr_z {
    margin-left: 0px !important;
    width: 38%;
  }
  .vcxaaaq_ss {
    display: flex;
    width: 60%;
    flex-direction: row;
    justify-content: space-between;
  }
  .fgbwexz4 {
    margin-right: 3vw !important;
    height: 40px !important;
    width: 40vw;
    margin-right: 0px !important;
  }
  .amol2 {
    margin-right: 0px !important;
    flex-direction: column;
    width: 100%;
    background: #002f87;
    align-items: left !important;
  }
  header .hujnsacbdfv_s .amol2 {
    align-items: baseline;
    padding-top: 20px;
  }
  header .hujnsacbdfv_s {
    height: auto;
  }
  .men5 {
    display: flex;
    align-items: end !important;
    width: 10vw;
  }
  .men5 img {
    max-width: 100% !important;
    margin-top: 2px;
    height: 8vw;
    margin-left: 2vw;
  }
  .clss {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 10px;
    align-items: center;
    padding: 0px 5%;
    margin-bottom: 10px;
  }
  header .amol2 div:hover:before {
    content: ' ';
    position: absolute;
    height: 100%;
    pointer-events: none;
    border-top: 0px solid;
    background-color: #c11832;
    border-left: 5px solid #f7111a;
    border-radius: 0px;
    left: -5vw;
    width: 100vw;
    top: 0px;
    z-index: -2;
  }
  .amol2 div {
    padding: 10px 4vw;
    width: 90vw;
    padding-left: 0px;
  }
  .cvbxasdeq-f a {
    width: 47%;
    height: 110px;
    margin: 0px;
    background-size: 100% 100%;
    margin-bottom: 120px;
    cursor: pointer;
    position: relative;
    margin: 0px 1%;
    margin-bottom: 10px;
    font-size: 8px;
  }
  .cvbxasdeq-f2 {
    height: 30px;
  }
  .cvbxasdeq-f1 {
    height: 110px;
  }
  .cvbxasdeq-f-end {
    margin: 20px auto;
  }
  .cvbxasdeq-f a .cvbxasdeq-f-0 {
    position: absolute;
    content: ' ';
    width: 100%;
    z-index: 1;
    height: 100px;
  }
  h1:before,
  h2:before,
  h3:before,
  h4:before,
  h5:before,
  h6:before,
  strong:before {
    display: none;
  }
  strong,
  h1 {
    padding-left: 0px;
  }
  .cvbxasdeq-f a:hover .cvbxasdeq-f-0 {
    height: 60px;
  }
  .ghadsbvn div {
    /*color: #fff;*/
    font-size: 16px;
    padding: 0px 0px;
  }
  .jn_bsadflk3 {
    padding: 0px 1vw;
    font-size: 14px;
    width: 20vw;
    height: 40px !important;
  }
  .fgbwexz4 {
    //padding: 0px 7vw;
    font-size: 14px;
    /* width: 32vw; */
    height: 40px !important;
  }
  .nmasdfr_z img {
    width: 100%;
    z-index: 3;
    margin-top: 0px;
    display: block;
  }
  header .ghadsbvn div:hover:before {
    top: 12px;
    display: none;
  }
  p strong {
    margin-top: 0px;
  }
  h2 {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1000px) {
  h2,
  h3,
  h4,
  h5,
  p strong {
    padding-left: 0px;
  }

  .nebgnasdfrt_nzcx {
    display: none !important;
  }
  .ghadsbvn a {
    margin: 0px 10px;
    white-space: nowrap;
    //padding: 25px 0px;
  }
  .ghadsbvn {
    display:inline;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  h1:before,
  h2:before,
  h3:before,
  h4:before,
  h5:before,
  h6:before,
  p strong:before {
    display: none;
  }
  p,
  li,
  h2,
  h3,
  h4,
  h5,
  h6,
  h1 {
    padding-left: 0px;
  }
  .vxcbcvxasde_left {
    width: 100%;
    padding-right: 0px;
  }
  .vdfwqzm_ert {
    width: 100%;
  }
  p,
  li {
    line-height: 1.6;
    font-size: 14px;
  }
  header .ghadsbvn div:hover:after {
    display: none;
  }
  h2,
  h3,
  h4,
  h5,
  p strong {
    font-size: 18px;
  }
}
.vbxaweqv {
  cursor: pointer;
  width: 100%;
  margin: 10px;
}

.vbxaweqvv {
  cursor: pointer;
  width: 100%;
}

img[src='/assets/images/reg_button.png'] {
  width: auto;
  text-align: center;
  vertical-align: middle;
}

.alert {
  padding: 20px;
  background-color: #f44336;
  color: white;
}

.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}

A {
  /*font-size: 14px;*/
}

a:hover {
  color: #000;
}
.images_row {
  display: flex;
  align-items: center;
}
.cvxqq6 {
  background: #cd3334;
  border-radius: 15px;

  padding: 15px;
  border: 1px solid #fffff1;
}

.cvxqq6 {
  margin-right: 15px !important;
}
.cvxqq6:hover,
.fgbwexz4:hover {
  /*background:#fff;*/
}
